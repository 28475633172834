export const data = {
  "nodes": [
    {
      "id": "Alcohol & DNA",
      "tldr": [
        "Alcohol alters DNA methylation, disrupting gene expression and elevating cancer risks, especially breast cancer. ",
        "Fetal alcohol exposure causes severe, irreversible developmental damage. ",
        "Chronic drinking disturbs hormonal balance, converting testosterone into estrogen, increasing fat storage, and boosting cancer risks. ",
        "Low-to-moderate alcohol intake disrupts the gut-liver-brain axis, triggering systemic inflammation and compounding health issues. ",
        "Alcohol’s reach extends into our biology, damaging DNA, gut health, and neural circuits while altering hormonal balance and immune function."
      ],
      "connections": [
        "Level up your gut!",
        "Non-food foods"
      ],
      "url": "https://wellawarecare.substack.com/p/alcohol-and-dna"
    },
    {
      "id": "Fermented food benefits",
      "tldr": [
        "A diverse gut microbiome supports immunity, hormone balance, and mental health. ",
        "Fermented foods like sauerkraut, kefir, and Greek yogurt improve microbial diversity and reduce inflammation. ",
        "Fiber’s effects on gut health vary, but low-sugar fermented foods offer consistent benefits. ",
        "Supplements with probiotics, prebiotics, and digestive enzymes are essential for those lacking dietary support. ",
        "Moderation is crucial—excessive supplement use can cause issues like brain fog."
      ],
      "connections": [
        "The real spice, girl!",
        "Level up your gut!",
        "Explosive & toxic chemicals in food",
        "How do you like them apples?",
        "Non-food foods"
      ],
      "url": "https://wellawarecare.substack.com/p/fermented-food-benefits"
    },
    {
      "id": "High performance sleeping",
      "tldr": [
        "Sleep consistency is more crucial than total sleep duration for optimal health and performance. ",
        "Sleep timing and aligning with your circadian rhythm are key factors for maximizing brain and body performance. ",
        "Improved sleep can reduce cortisol, improve athletic performance, and enhance cognitive abilities. ",
        "Small sleep improvements, like adding 30 extra minutes, significantly boost immune function, reduce body fat, and improve recovery. ",
        "High-intensity exercise late in the evening can interfere with sleep quality, so switching to restorative exercises at night is recommended."
      ],
      "connections": [
        "Be better than the conventional normal",
        "How does fasting effect hormones?",
        "One third of your youth"
      ],
      "url": "https://wellawarecare.substack.com/p/high-performance-sleeping"
    },
    {
      "id": "Lactose in-tolerance_",
      "tldr": [
        "Around 65% of the global population is lactose intolerant, and the prevalence increases with age. ",
        "Lactose intolerance symptoms occur when the body cannot properly digest dairy’s carbohydrate content due to low levels of lactase. ",
        "Gut health and skin conditions are closely linked, making dairy intolerance a potential trigger for skin issues like acne and eczema. ",
        "Dairy alternatives and lactase enzymes can offer solutions, but eliminating dairy often improves gut health. ",
        "Non-dairy sources of calcium, such as almonds, sardines, and leafy greens, provide nutrient-rich alternatives to dairy."
      ],
      "connections": [
        "Level up your gut!",
        "Explosive & toxic chemicals in food",
        "Vitamin D Deficiency"
      ],
      "url": "https://wellawarecare.substack.com/p/lactose-in-tolerance_"
    },
    {
      "id": "Less (eating) is more (healthy)",
      "tldr": [
        "Fasting progresses through six stages, from blood sugar stabilization to immune regeneration, offering diverse health benefits. ",
        "Drinks like water, tea, and coffee support fasting, but additives like sugar or alcohol disrupt its effects. ",
        "Prolonged fasting activates autophagy and stem cell regeneration, enhancing cellular cleanup and immune health. ",
        "Explore fasting’s physiological changes, from fat burning to immune renewal."
      ],
      "connections": [
        "Faster weight loss on slow thyroid",
        "The healthy hungry age better",
        "Your energy source",
        "Belly fat!",
        "How does fasting effect hormones?",
        "Level up your gut!"
      ],
      "url": "https://wellawarecare.substack.com/p/less-(eating)-is-more-(healthy)"
    },
    {
      "id": "Nasties in beauty",
      "tldr": [
        "Silicone breast implants can leach harmful substances like platinum, causing inflammation and health risks. ",
        "Makeup removers and beauty products often include chemicals that increase toxin absorption through the skin. ",
        "Phthalates in fragrances disrupt hormones, reducing testosterone and affecting fertility. ",
        "BPA in plastics and beauty packaging promotes weight gain and metabolic issues. ",
        "A minimalist, natural approach to skincare reduces toxic exposure, supports hormone balance, and improves detox pathways."
      ],
      "connections": [
        "Femme fatale fragrance",
        "Bonds, alpha females & botox",
        "The real spice, girl!"
      ],
      "url": "https://wellawarecare.substack.com/p/nasties-in-beauty"
    },
    {
      "id": "Pick not Ozempic",
      "tldr": [
        "Willpower is no match for your evolutionary instincts, which prioritize survival over food control. ",
        "Stress, sleep deprivation, and ultra-processed foods worsen cravings and disrupt appetite hormones. ",
        "Drugs like Ozempic can help with weight loss but don’t address behavioral changes or root causes. ",
        "Muscle loss, slower metabolism, and severe side effects are significant downsides of long-term Ozempic use. ",
        "“If you're a severely obese person, you have severe health conditions, you're really struggling to get going, can it be a helpful aid? Absolutely. Should it be given to everybody who wants to look good for a party or a wedding? Absolutely not.”"
      ],
      "connections": [
        "Your energy source"
      ],
      "url": "https://wellawarecare.substack.com/p/pick-not-ozempic"
    },
    {
      "id": "Supplementation is not expensive urine",
      "tldr": [
        "Foundational supplements provide essential nutrients, digestive enzymes, and adaptogens to bridge dietary gaps. ",
        "Single-ingredient formulations target specific deficiencies with precision and avoid unnecessary compounds. ",
        "Balance fat-soluble (A, D, E, K) and water-soluble vitamins to optimize health and prevent toxicity. ",
        "Gut health support through probiotics, prebiotics, and digestive enzymes enhances nutrient absorption and overall wellness. ",
        "Tailor supplementation to your lifestyle, diet, and unique needs for maximum benefit."
      ],
      "connections": [
        "Power up with potassium",
        "Supplements for your pretty face",
        "Brain health 101"
      ],
      "url": "https://wellawarecare.substack.com/p/supplementation-is-not-expensive-urine"
    },
    {
      "id": "Supplements for your pretty face",
      "tldr": [
        "Mitochondria are your body’s energy factories, and supporting their function is crucial for anti-aging and vitality. ",
        "Glutathione not only detoxifies your liver but also strengthens mitochondrial health. ",
        "Clean beauty products can reduce exposure to hormonal disruptors, supporting both skin and hormonal balance. ",
        "By focusing on mitochondrial health and clean beauty, you can boost energy, improve skin, and enhance overall well-being."
      ],
      "connections": [
        "One third of your youth"
      ],
      "url": "https://wellawarecare.substack.com/p/supplements-for-your-pretty-face"
    },
    {
      "id": "The genetic disconnect",
      "tldr": [
        "Our genes remain largely unchanged for 250,000 years, yet modern life introduces stressors that our bodies aren't designed to handle. ",
        "Epigenetics, influenced by diet and environment, determines how genes are expressed and can affect future generations. ",
        "Environmental toxins, such as plastics and phthalates, disrupt hormones and gene expression, contributing to health challenges like infertility and cognitive decline. ",
        "mRNA and CRISPR technologies are revolutionizing genetic science but carry unknown long-term risks. ",
        "Managing environmental and lifestyle factors is essential for adapting to modern challenges and maintaining genetic health."
      ],
      "connections": [],
      "url": "https://wellawarecare.substack.com/p/the-genetic-disconnect"
    },
    {
      "id": "When to eat_",
      "tldr": [
        "When you eat is as important as what you eat for health, especially liver and mental health. ",
        "Time-restricted feeding (TRF) helps align your body's 24-hour rhythm (circadian clock), boosting health and reducing risks. ",
        "Eating during your active phase of the day (daylight hours) enhances metabolic health and prevents liver damage. ",
        "Short feeding windows (4–6 hours) may increase overeating, while 7–9 hour windows show consistent health benefits. ",
        "Fasting impacts brain and body, supporting clarity, hormone balance, and fat loss when coupled with caloric restriction."
      ],
      "connections": [
        "How does fasting effect hormones?",
        "The healthy hungry age better"
      ],
      "url": "https://wellawarecare.substack.com/p/when-to-eat_"
    },
    {
      "id": "Zinc it up",
      "tldr": [
        "Zinc is essential for over 300 enzymatic reactions, impacting everything from hormones to skin health. ",
        "Zinc deficiency can manifest as digestive issues, mood changes, and low testosterone. ",
        "Supplementing with zinc and eating zinc-rich foods helps support cognitive function, hormone balance, and sleep. ",
        "Prioritize zinc to enhance your immune system, improve skin health, and boost overall well-being."
      ],
      "connections": [
        "Your energy source",
        "Be better than the conventional normal",
        "High performance sleeping",
        "Bread!",
        "Level up your gut!",
        "Power up with potassium",
        "Supplements for your pretty face"
      ],
      "url": "https://wellawarecare.substack.com/p/zinc-it-up"
    },
    {
      "id": "Be better than the conventional normal",
      "tldr": [
        "Standard blood test ranges focus on “normal,” but aiming for optimal levels leads to better health outcomes. ",
        "Vitamin D, even if within a “normal” range, often needs to be optimized for bone, muscle, immune, and cognitive health. ",
        "Fasting blood glucose levels, though not classified as diabetic, can still signal risks if they exceed optimal ranges. ",
        "Potassium, hydration, and inflammation markers like albumin offer deeper insights into overall health. ",
        "Assessing sleep quality and environmental factors is essential for both immediate well-being and long-term health."
      ],
      "connections": [
        "Explosive & toxic chemicals in food",
        "Power up with potassium",
        "High performance sleeping"
      ],
      "url": "https://wellawarecare.substack.com/p/be-better-than-the-conventional-normal"
    },
    {
      "id": "Belly fat!",
      "tldr": [
        "Excess sugar and hormones like cortisol and estrogen mimickers can cause belly fat. Monitoring blood sugar is more effective than calorie counting. ",
        "Avoid diet foods with artificial ingredients; opt for sparkling water, mineral water, or tea. ",
        "Stay active to manage stress and eat plenty of vegetables for gut health. Get enough deep sleep to regulate hormones and prevent weight gain."
      ],
      "connections": [
        "Bread!",
        "One third of your youth",
        "High performance sleeping",
        "Less (eating) is more (healthy)"
      ],
      "url": "https://wellawarecare.substack.com/p/care-13-belly-fat"
    },
    {
      "id": "Bonds, alpha females & botox",
      "tldr": [
        "Eye contact, especially right-eye-to-left-eye, is key for emotional bonding. ",
        "Women who spend time together often sync their cycles, influenced by the alpha female. ",
        "Behaviors and attitudes, like divorce or weight gain, can spread through social groups. ",
        "Good health, shown in appearance and energy, often leads to better social and romantic interaction"
      ],
      "connections": [
        "Nasties in beauty"
      ],
      "url": "https://wellawarecare.substack.com/p/care-15-bonds-alpha-females-and-botox"
    },
    {
      "id": "Brain health 101",
      "tldr": [
        "Brain health is foundational to mental health; daily habits like nutrition, sleep, and avoiding toxins are critical. ",
        "Poor lifestyle choices—like neglecting oral health, consuming harmful substances, or not engaging in lifelong learning—can negatively impact brain function. ",
        "Prioritizing Omega-3s, Vitamin D, and regular hormone checks supports optimal brain function and reduces dementia risk. ",
        "Addressing emotional and spiritual well-being through self-reflection, purpose, and relationships enhances overall brain health."
      ],
      "connections": [
        "Effects of mouth's proximity to the brain",
        "Welcome the suck",
        "Non-food foods",
        "High performance sleeping",
        "Power up with potassium"
      ],
      "url": "https://wellawarecare.substack.com/p/brain-health-101"
    },
    {
      "id": "Bread!",
      "tldr": [
        "Eliminating processed grains for 14 days stabilizes blood sugar and reduces inflammation. ",
        "Healthy grains, like sourdough, enhance mood, digestion, and hunger management. ",
        "Avoiding bad grains repairs the gut lining, decreases joint pain, and improves sleep quality. ",
        "Learn how to distinguish between healthy and unhealthy grains to optimize health benefits."
      ],
      "connections": [
        "The real spice, girl!",
        "Belly fat!",
        "How does fasting effect hormones?",
        "How do you like them apples?",
        "High performance sleeping"
      ],
      "url": "https://wellawarecare.substack.com/p/bread"
    },
    {
      "id": "Don_t de-health while deodorizing",
      "tldr": [
        "Conventional deodorants, especially antiperspirants with aluminum salts, pose health risks by blocking sweat ducts and increasing toxic absorption. ",
        "Aluminum salts in antiperspirants are linked to higher breast cancer risk, particularly when used before age 30. ",
        "Aerosol deodorants carry additional inhalation risks, and petroleum byproducts in some deodorants can contain carcinogenic compounds. ",
        "Natural alternatives like coconut oil, baking soda, and essential oils provide safer options. ",
        "An armpit detox with clay can help remove residual aluminum salts and reset natural body odor."
      ],
      "connections": [
        "Femme fatale fragrance",
        "Ketogenic diet and cancer",
        "How does fasting effect hormones?",
        "Nasties in beauty",
        "The genetic disconnect"
      ],
      "url": "https://wellawarecare.substack.com/p/don_t-de-health-while-deodorizing"
    },
    {
      "id": "Effects of mouth_s proximity to the brain",
      "tldr": [
        "Oral bacteria, like P. gingivalis, can cross the blood-brain barrier and are linked to Alzheimer’s. ",
        "Mouth breathing, poor diet, and inadequate oral hygiene practices rank as top causes of gum disease. ",
        "Flossing, tongue scraping, and gum massage play crucial roles in maintaining oral health and preventing systemic diseases. ",
        "Certain mouthwashes can harm the oral microbiome; gentle brushing and eco-friendly tools are recommended."
      ],
      "connections": [
        "Non-food foods"
      ],
      "url": "https://wellawarecare.substack.com/p/effects-of-mouth_s-proximity-to-the-brain"
    },
    {
      "id": "Explosive & toxic chemicals in food",
      "tldr": [
        "High deuterium in processed foods accelerates aging; low-deuterium diets are healthier. ",
        "Glyphosate disrupts liver function and microbiomes, and harms wildlife. ",
        "Don't take calcium with vitamin D to avoid calcium buildup. Vitamin K2 helps direct calcium to bones. ",
        "Sunlight boosts melatonin and skin protection. Deuterium, glyphosate, and vitamin D deficiency affect telomere health."
      ],
      "connections": [
        "Bread!",
        "Is organic food worth it?",
        "Level up your gut!",
        "Femme fatale fragrance",
        "Functional Medicine Functioning",
        "Your energy source"
      ],
      "url": "https://wellawarecare.substack.com/p/care-14-explosive-and-toxic-chemicals"
    },
    {
      "id": "Faster weight loss on slow thyroid",
      "tldr": [
        "Fasting, especially intermittent fasting and OMAD (One Meal A Day), boosts metabolism, enhances cognitive function, and promotes gut health through autophagy. ",
        "People with slow thyroid or thyroid removal can benefit from transitioning fuel sources from glucose to ketones, aiding in weight loss and improving metabolic efficiency. ",
        "Prebiotic fiber and fasting hacks ease hunger, promote satiety, and support gut health during fasting. ",
        "A gradual progression from three meals a day to OMAD helps stabilize insulin levels and supports fat burning, especially in those with thyroid issues."
      ],
      "connections": [
        "How does fasting effect hormones?",
        "Functional Medicine Functioning",
        "How do you like them apples?",
        "Level up your gut!"
      ],
      "url": "https://wellawarecare.substack.com/p/faster-weight-loss-on-slow-thyroid"
    },
    {
      "id": "Femme fatale fragrance",
      "tldr": [
        "Phthalates, used as fragrance fixatives in many feminine care products, are estrogenic compounds that disrupt hormonal balance and pose serious health risks. ",
        "Feminine care products like scented tampons, pads, and period underwear can deliver harmful chemicals directly to sensitive areas, bypassing the body’s natural detox pathways. ",
        "Organic cotton pads, medical-grade silicone menstrual cups, and PFAS-tested period underwear offer safer alternatives. ",
        "The highly absorptive nature of the vaginal area makes it critical to avoid products containing harmful chemicals, including plasticizers and PFAS."
      ],
      "connections": [],
      "url": "https://wellawarecare.substack.com/p/femme-fatale-fragrance"
    },
    {
      "id": "Fruit juice to fatty liver",
      "tldr": [
        "Fruit juice, despite being perceived as healthy, can cause fatty liver due to its high fructose content. ",
        "Fructose is metabolized by the liver and can lead to metabolic syndrome, obesity, and non-alcoholic fatty liver disease (NAFLD). ",
        "The lack of fiber in fruit juice accelerates fructose absorption, increasing health risks even without weight gain. ",
        "Smoothies with fruit juices can spike blood sugar levels, sometimes higher than sodas."
      ],
      "connections": [
        "The sugar consequences of salt",
        "One third of your youth",
        "Is organic food worth it?",
        "Less (eating) is more (healthy)",
        "When to eat?"
      ],
      "url": "https://wellawarecare.substack.com/p/care-16-fruit-juice-to-fatty-liver"
    },
    {
      "id": "Functional Medicine Functioning",
      "tldr": [
        "Autoimmune diseases are linked to immune dysfunction, inflammation, gut issues, and stress. ",
        "Functional medicine treats root causes, considering allergens, microbes, toxins, stress, and diet. ",
        "To reverse autoimmune diseases: eliminate mold, eat whole foods, test for infections, address gluten sensitivity, and heal the gut. ",
        "Vitamin D is essential for managing autoimmune conditions, hormone balance in women, and testosterone and bone health in men."
      ],
      "connections": [
        "Your energy source"
      ],
      "url": "https://wellawarecare.substack.com/p/care-11-functional-medicine-functioning"
    },
    {
      "id": "How do you like them apples_",
      "tldr": [
        "Apple cider vinegar stabilizes blood sugar, managing glycation and supporting oxygen delivery, key factors for slowing aging. ",
        "As a prebiotic, it enhances gut health, serotonin production, and mood, benefiting brain health and neuroplasticity. ",
        "It supports fat burning and metabolic health, making it a valuable addition to a longevity-focused lifestyle. ",
        "Lowering Hemoglobin A1C levels with apple cider vinegar can reduce diabetes risk and improve overall well-being. ",
        "Incorporating apple cider vinegar aids in transitioning into a fasted state and amplifies the anti-aging effects of fasting."
      ],
      "connections": [
        "Functional Medicine Functioning",
        "Level up your gut!",
        "Belly fat!",
        "The healthy hungry age better"
      ],
      "url": "https://wellawarecare.substack.com/p/how-do-you-like-them-apples_"
    },
    {
      "id": "How does fasting effect hormones_",
      "tldr": [
        "Fasting boosts serotonin for better mood and sleep, increases growth hormone for anti-aging, and raises glucagon for fat burning. ",
        "Fasting can regulate blood sugar through adrenaline and cortisol, and help reverse leptin resistance. ",
        "Lack of sleep increases hunger hormones; glycine can improve insulin sensitivity and reduce kidney stone risk."
      ],
      "connections": [
        "The healthy hungry age better",
        "High performance sleeping",
        "Faster weight loss on slow thyroid"
      ],
      "url": "https://wellawarecare.substack.com/p/how-does-fasting-effect-hormones_"
    },
    {
      "id": "Is organic food worth it_",
      "tldr": [
        "Organic food is better for preventing chronic diseases, but non-organic is okay if it has fewer pesticides and your health is good. ",
        "Pesticides can disrupt hormones, leading to issues like belly fat. ",
        "Glyphosate, a common pesticide, harms gut health and reduces biodiversity."
      ],
      "connections": [
        "Explosive & toxic chemicals in food",
        "Level up your gut!",
        "Functional Medicine Functioning",
        "Your energy source",
        "Non-food foods"
      ],
      "url": "https://wellawarecare.substack.com/p/care-12-is-organic-food-worth-it_"
    },
    {
      "id": "Ketogenic diet and cancer",
      "tldr": [
        "The ketogenic diet starves cancer cells by limiting glucose, their primary fuel source, potentially slowing tumor growth. ",
        "Ketosis can reduce inflammation, enhance recovery, and potentially work synergistically with conventional treatments like chemotherapy. ",
        "Addressing mitochondrial health and the body’s signaling systems is crucial in understanding cancer’s complexities and potential treatment strategies. ",
        "Embracing a holistic approach, including diet, sleep, and emotional well-being, may offer new hope in cancer care."
      ],
      "connections": [
        "Functional Medicine Functioning",
        "The real spice, girl!"
      ],
      "url": "https://wellawarecare.substack.com/p/ketogenic-diet-and-cancer"
    },
    {
      "id": "Level up your gut!",
      "tldr": [
        "Eat a variety of fiber-rich and fermented foods for a diverse gut microbiome. ",
        "Avoid sugar, alcohol, antibiotics, GMO foods, and artificial sweeteners to maintain gut diversity. ",
        "Intermittent fasting and coffee (without sugar/milk) support gut health and overall well-being. ",
        "A healthy microbiome aids immune function, hormone regulation, and mood."
      ],
      "connections": [
        "Explosive & toxic chemicals in food",
        "Level up your gut!",
        "Bread!",
        "Is organic food worth it?",
        "Non-food foods"
      ],
      "url": "https://wellawarecare.substack.com/p/level-up-your-gut"
    },
    {
      "id": "Non-food foods",
      "tldr": [
        "Excessive sugar intake and ultra-processed foods are linked to metabolic disorders, cognitive decline, and mental health issues. ",
        "Aspartame and non-nutritive sweeteners may impact cognitive health by disrupting neurotransmitters and gut microbiome balance. ",
        "Even moderate alcohol consumption affects brain structure and function, increasing cancer risks and psychological dependence. ",
        "Seed oils high in omega-6 fatty acids contribute to inflammation, oxidative stress, and mitochondrial damage, potentially due to misleading health recommendations."
      ],
      "connections": [
        "The sugar consequences of salt",
        "Explosive & toxic chemicals in food",
        "Is organic food worth it?",
        "Level up your gut!",
        "Brain health 101",
        "Your energy source",
        "Power up with potassium"
      ],
      "url": "https://wellawarecare.substack.com/p/non-food-foods"
    },
    {
      "id": "One third of your youth",
      "tldr": [
        "Glycine, vital for collagen production, is often deficient in modern diets, leading to various health issues. ",
        "It supports antioxidant production, liver detoxification, and gut health, helping to prevent autoimmune diseases. ",
        "Glycine enhances sleep, insulin sensitivity, and blood sugar regulation, making it valuable for managing conditions like gout and fatty liver. ",
        "It can also prevent kidney stones and improve overall metabolic health by balancing insulin and glucagon. ",
        "Simple dietary changes, such as consuming gelatin or bone broth, can boost glycine intake."
      ],
      "connections": [
        "Your energy source",
        "Supplements for your pretty face",
        "Level up your gut!",
        "High performance sleeping",
        "Fermented food benefits",
        "The genetic disconnect"
      ],
      "url": "https://wellawarecare.substack.com/p/one-third-of-your-youth"
    },
    {
      "id": "Power up with potassium",
      "tldr": [
        "Potassium is a critical nutrient with a daily requirement of 4700 milligrams, far exceeding most people’s intake. ",
        "A deficiency in potassium can lead to muscle cramps, high blood pressure, and heart palpitations, among other issues. ",
        "Processed foods, high-carb diets, and certain medications can deplete potassium levels. ",
        "Including potassium-rich foods, hydrating properly, and being mindful during fasting can prevent serious imbalances."
      ],
      "connections": [
        "Your energy source",
        "Supplements for your pretty face"
      ],
      "url": "https://wellawarecare.substack.com/p/power-up-with-potassium"
    },
    {
      "id": "Preventing Alzheimer’s with lifestyle choices",
      "tldr": [
        "Up to 40% of Alzheimer’s cases are linked to modifiable lifestyle factors. ",
        "Factors like gender, diet, exercise, sleep, and metabolic health significantly impact Alzheimer’s risk. ",
        "Avoiding processed foods, supporting metabolic health, and eating brain-boosting nutrients like DHA from fish and antioxidants can reduce the risk of cognitive decline."
      ],
      "connections": [
        "Brain health 101",
        "Effects of mouth's proximity to the brain",
        "Non-food foods"
      ],
      "url": "https://wellawarecare.substack.com/p/preventing-alzheimers-with-lifestyle"
    },
    {
      "id": "Take 11 minutes a day to postpone death",
      "tldr": [
        "Regular exercise is the most potent natural remedy for living longer and reducing disease risk. ",
        "Building a \"fitness reserve\" gives your body a health buffer to counter stress, improve metabolism, and enhance flexibility. ",
        "Short bursts of high-intensity exercise throughout the day can dramatically lower mortality rates and reduce risks of cardiovascular disease and cancer. ",
        "Activities like walking after meals, using tools like the Relentless Rope Trainer and mobility sticks, and even sauna sessions can boost fitness and support overall well-being. ",
        "Consistency in enjoyable fitness activities and using heart rate variability (HRV) technology to monitor stress and recovery can optimize exercise routines."
      ],
      "connections": [],
      "url": "https://wellawarecare.substack.com/p/take-11-minutes-a-day-to-postpone"
    },
    {
      "id": "The healthy hungry age better",
      "tldr": [
        "Fasting is not only safe but essential for older adults, offering numerous benefits like life extension and activation of anti-aging genes. ",
        "It helps alleviate age-related health issues such as obesity, high blood pressure, and dementia by promoting metabolic flexibility. ",
        "Fasting supports DNA and mitochondrial repair through autophagy, enhancing cellular function and reducing cancer risk. ",
        "It boosts heart and brain health by increasing ketone production and stimulating neuronal regeneration with BDNF. ",
        "The practice enhances the body's antioxidant network, reducing free radical damage and supporting cardiovascular health."
      ],
      "connections": [
        "How does fasting effect hormones?",
        "Brain health 101",
        "Your energy source",
        "Less (eating) is more (healthy)",
        "Faster weight loss on slow thyroid",
        "When to eat?"
      ],
      "url": "https://wellawarecare.substack.com/p/the-healthy-hungry-age-better"
    },
    {
      "id": "The real spice, girl!",
      "tldr": [
        "Turmeric, used in ancient medicine, contains curcumin, a powerful polyphenol with poor natural absorption. ",
        "For better absorption, pair turmeric with black pepper or supplements containing bioparen. ",
        "Curcumin reduces dementia risk, supports brain health, alleviates joint pain, and even fights cancer. ",
        "Turmeric aids women’s health by improving mood and managing PMS symptoms. ",
        "High-polyphenol spices like turmeric promote gut health and weight management."
      ],
      "connections": [
        "Brain health 101",
        "Level up your gut!"
      ],
      "url": "https://wellawarecare.substack.com/p/the-real-spice-girl"
    },
    {
      "id": "The sugar consequences of salt",
      "tldr": [
        "Fructose is produced in the liver when glucose is high and causes insulin resistance, leading to fat gain and metabolic syndrome. ",
        "High salt intake without enough water triggers fructose production and fat storage, contributing to metabolic problems. ",
        "Fructose and certain foods raise uric acid levels, causing oxidative stress and a cycle of increased fructose production, worsening obesity. ",
        "High fructose and uric acid levels damage blood vessels, making arterial plaque stickier and increasing heart disease risk."
      ],
      "connections": [
        "Your energy source",
        "Fruit juice to fatty liver",
        "One third of your youth",
        "Non-food foods"
      ],
      "url": "https://wellawarecare.substack.com/p/the-sugar-consequences-of-salt"
    },
    {
      "id": "Vitamin D Deficiency",
      "tldr": [],
      "connections": [
        "Be better than the conventional normal",
        "Explosive & toxic chemicals in food",
        "Functional Medicine Functioning",
        "Supplementation is.not expensive urine",
        "Nasties in beauty"
      ],
      "url": "https://wellawarecare.substack.com/p/vitamin-d-deficiency"
    },
    {
      "id": "Welcome the suck",
      "tldr": [
        "Happiness is a balance of enjoyment, satisfaction, and meaning, and is processed differently in the brain. ",
        "Struggling, deferred gratification, and effort are necessary components of long-term satisfaction. ",
        "Suffering is essential for growth and self-awareness, and embracing it can lead to a deeper sense of happiness. ",
        "Deep relationships, meaningful work, and teaching others play critical roles in achieving happiness and mastery."
      ],
      "connections": [
        "How does fasting effect hormones?"
      ],
      "url": "https://wellawarecare.substack.com/p/welcome-the-suck"
    },
    {
      "id": "Your energy source",
      "tldr": [
        "Mitochondria are your body’s energy producers, converting food and oxygen into ATP, the fuel for all cellular functions. ",
        "Damage to mitochondria, often caused by oxidative stress and environmental toxins, leads to fatigue, memory loss, and rapid aging. ",
        "Reducing sugar, processed foods, and toxins can protect mitochondria and enhance energy levels. ",
        "Exercise, especially interval training and strength training, along with antioxidant-rich plant foods, support mitochondrial health. ",
        "Key supplements like CoQ10, resveratrol, and omega-3s can further boost mitochondrial function and energy production."
      ],
      "connections": [
        "Explosive & toxic chemicals in food",
        "Functional Medicine Functioning",
        "Fruit juice to fatty liver",
        "Power up with potassium",
        "Ketogenic diet and cancer",
        "Take 11 minutes a day to postpone death",
        "The healthy hungry age better",
        "Be better than the conventional normal",
        "Faster weight loss on slow thyroid",
        "Supplements for your pretty face",
        "Supplementation is.not expensive urine"
      ],
      "url": "https://wellawarecare.substack.com/p/your-energy-source"
    }
  ],
  "links": [
    {
      "source": "Alcohol & DNA",
      "target": "Level up your gut!"
    },
    {
      "source": "Alcohol & DNA",
      "target": "Non-food foods"
    },
    {
      "source": "Fermented food benefits",
      "target": "The real spice, girl!"
    },
    {
      "source": "Fermented food benefits",
      "target": "Level up your gut!"
    },
    {
      "source": "Fermented food benefits",
      "target": "Explosive & toxic chemicals in food"
    },
    {
      "source": "Fermented food benefits",
      "target": "Non-food foods"
    },
    {
      "source": "High performance sleeping",
      "target": "Be better than the conventional normal"
    },
    {
      "source": "High performance sleeping",
      "target": "One third of your youth"
    },
    {
      "source": "Lactose in-tolerance_",
      "target": "Level up your gut!"
    },
    {
      "source": "Lactose in-tolerance_",
      "target": "Explosive & toxic chemicals in food"
    },
    {
      "source": "Lactose in-tolerance_",
      "target": "Vitamin D Deficiency"
    },
    {
      "source": "Less (eating) is more (healthy)",
      "target": "Faster weight loss on slow thyroid"
    },
    {
      "source": "Less (eating) is more (healthy)",
      "target": "The healthy hungry age better"
    },
    {
      "source": "Less (eating) is more (healthy)",
      "target": "Your energy source"
    },
    {
      "source": "Less (eating) is more (healthy)",
      "target": "Belly fat!"
    },
    {
      "source": "Less (eating) is more (healthy)",
      "target": "Level up your gut!"
    },
    {
      "source": "Nasties in beauty",
      "target": "Femme fatale fragrance"
    },
    {
      "source": "Nasties in beauty",
      "target": "Bonds, alpha females & botox"
    },
    {
      "source": "Nasties in beauty",
      "target": "The real spice, girl!"
    },
    {
      "source": "Pick not Ozempic",
      "target": "Your energy source"
    },
    {
      "source": "Supplementation is not expensive urine",
      "target": "Power up with potassium"
    },
    {
      "source": "Supplementation is not expensive urine",
      "target": "Supplements for your pretty face"
    },
    {
      "source": "Supplementation is not expensive urine",
      "target": "Brain health 101"
    },
    {
      "source": "Supplements for your pretty face",
      "target": "One third of your youth"
    },
    {
      "source": "When to eat_",
      "target": "The healthy hungry age better"
    },
    {
      "source": "Zinc it up",
      "target": "Your energy source"
    },
    {
      "source": "Zinc it up",
      "target": "Be better than the conventional normal"
    },
    {
      "source": "Zinc it up",
      "target": "High performance sleeping"
    },
    {
      "source": "Zinc it up",
      "target": "Bread!"
    },
    {
      "source": "Zinc it up",
      "target": "Level up your gut!"
    },
    {
      "source": "Zinc it up",
      "target": "Power up with potassium"
    },
    {
      "source": "Zinc it up",
      "target": "Supplements for your pretty face"
    },
    {
      "source": "Be better than the conventional normal",
      "target": "Explosive & toxic chemicals in food"
    },
    {
      "source": "Be better than the conventional normal",
      "target": "Power up with potassium"
    },
    {
      "source": "Be better than the conventional normal",
      "target": "High performance sleeping"
    },
    {
      "source": "Belly fat!",
      "target": "Bread!"
    },
    {
      "source": "Belly fat!",
      "target": "One third of your youth"
    },
    {
      "source": "Belly fat!",
      "target": "High performance sleeping"
    },
    {
      "source": "Belly fat!",
      "target": "Less (eating) is more (healthy)"
    },
    {
      "source": "Bonds, alpha females & botox",
      "target": "Nasties in beauty"
    },
    {
      "source": "Brain health 101",
      "target": "Welcome the suck"
    },
    {
      "source": "Brain health 101",
      "target": "Non-food foods"
    },
    {
      "source": "Brain health 101",
      "target": "High performance sleeping"
    },
    {
      "source": "Brain health 101",
      "target": "Power up with potassium"
    },
    {
      "source": "Bread!",
      "target": "The real spice, girl!"
    },
    {
      "source": "Bread!",
      "target": "Belly fat!"
    },
    {
      "source": "Bread!",
      "target": "High performance sleeping"
    },
    {
      "source": "Don_t de-health while deodorizing",
      "target": "Femme fatale fragrance"
    },
    {
      "source": "Don_t de-health while deodorizing",
      "target": "Ketogenic diet and cancer"
    },
    {
      "source": "Don_t de-health while deodorizing",
      "target": "Nasties in beauty"
    },
    {
      "source": "Don_t de-health while deodorizing",
      "target": "The genetic disconnect"
    },
    {
      "source": "Effects of mouth_s proximity to the brain",
      "target": "Non-food foods"
    },
    {
      "source": "Explosive & toxic chemicals in food",
      "target": "Bread!"
    },
    {
      "source": "Explosive & toxic chemicals in food",
      "target": "Level up your gut!"
    },
    {
      "source": "Explosive & toxic chemicals in food",
      "target": "Femme fatale fragrance"
    },
    {
      "source": "Explosive & toxic chemicals in food",
      "target": "Functional Medicine Functioning"
    },
    {
      "source": "Explosive & toxic chemicals in food",
      "target": "Your energy source"
    },
    {
      "source": "Faster weight loss on slow thyroid",
      "target": "Functional Medicine Functioning"
    },
    {
      "source": "Faster weight loss on slow thyroid",
      "target": "Level up your gut!"
    },
    {
      "source": "Fruit juice to fatty liver",
      "target": "The sugar consequences of salt"
    },
    {
      "source": "Fruit juice to fatty liver",
      "target": "One third of your youth"
    },
    {
      "source": "Fruit juice to fatty liver",
      "target": "Less (eating) is more (healthy)"
    },
    {
      "source": "Functional Medicine Functioning",
      "target": "Your energy source"
    },
    {
      "source": "How do you like them apples_",
      "target": "Functional Medicine Functioning"
    },
    {
      "source": "How do you like them apples_",
      "target": "Level up your gut!"
    },
    {
      "source": "How do you like them apples_",
      "target": "Belly fat!"
    },
    {
      "source": "How do you like them apples_",
      "target": "The healthy hungry age better"
    },
    {
      "source": "How does fasting effect hormones_",
      "target": "The healthy hungry age better"
    },
    {
      "source": "How does fasting effect hormones_",
      "target": "High performance sleeping"
    },
    {
      "source": "How does fasting effect hormones_",
      "target": "Faster weight loss on slow thyroid"
    },
    {
      "source": "Is organic food worth it_",
      "target": "Explosive & toxic chemicals in food"
    },
    {
      "source": "Is organic food worth it_",
      "target": "Level up your gut!"
    },
    {
      "source": "Is organic food worth it_",
      "target": "Functional Medicine Functioning"
    },
    {
      "source": "Is organic food worth it_",
      "target": "Your energy source"
    },
    {
      "source": "Is organic food worth it_",
      "target": "Non-food foods"
    },
    {
      "source": "Ketogenic diet and cancer",
      "target": "Functional Medicine Functioning"
    },
    {
      "source": "Ketogenic diet and cancer",
      "target": "The real spice, girl!"
    },
    {
      "source": "Level up your gut!",
      "target": "Explosive & toxic chemicals in food"
    },
    {
      "source": "Level up your gut!",
      "target": "Level up your gut!"
    },
    {
      "source": "Level up your gut!",
      "target": "Bread!"
    },
    {
      "source": "Level up your gut!",
      "target": "Non-food foods"
    },
    {
      "source": "Non-food foods",
      "target": "The sugar consequences of salt"
    },
    {
      "source": "Non-food foods",
      "target": "Explosive & toxic chemicals in food"
    },
    {
      "source": "Non-food foods",
      "target": "Level up your gut!"
    },
    {
      "source": "Non-food foods",
      "target": "Brain health 101"
    },
    {
      "source": "Non-food foods",
      "target": "Your energy source"
    },
    {
      "source": "Non-food foods",
      "target": "Power up with potassium"
    },
    {
      "source": "One third of your youth",
      "target": "Your energy source"
    },
    {
      "source": "One third of your youth",
      "target": "Supplements for your pretty face"
    },
    {
      "source": "One third of your youth",
      "target": "Level up your gut!"
    },
    {
      "source": "One third of your youth",
      "target": "High performance sleeping"
    },
    {
      "source": "One third of your youth",
      "target": "Fermented food benefits"
    },
    {
      "source": "One third of your youth",
      "target": "The genetic disconnect"
    },
    {
      "source": "Power up with potassium",
      "target": "Your energy source"
    },
    {
      "source": "Power up with potassium",
      "target": "Supplements for your pretty face"
    },
    {
      "source": "Preventing Alzheimer’s with lifestyle choices",
      "target": "Brain health 101"
    },
    {
      "source": "Preventing Alzheimer’s with lifestyle choices",
      "target": "Non-food foods"
    },
    {
      "source": "The healthy hungry age better",
      "target": "Brain health 101"
    },
    {
      "source": "The healthy hungry age better",
      "target": "Your energy source"
    },
    {
      "source": "The healthy hungry age better",
      "target": "Less (eating) is more (healthy)"
    },
    {
      "source": "The healthy hungry age better",
      "target": "Faster weight loss on slow thyroid"
    },
    {
      "source": "The real spice, girl!",
      "target": "Brain health 101"
    },
    {
      "source": "The real spice, girl!",
      "target": "Level up your gut!"
    },
    {
      "source": "The sugar consequences of salt",
      "target": "Your energy source"
    },
    {
      "source": "The sugar consequences of salt",
      "target": "Fruit juice to fatty liver"
    },
    {
      "source": "The sugar consequences of salt",
      "target": "One third of your youth"
    },
    {
      "source": "The sugar consequences of salt",
      "target": "Non-food foods"
    },
    {
      "source": "Vitamin D Deficiency",
      "target": "Be better than the conventional normal"
    },
    {
      "source": "Vitamin D Deficiency",
      "target": "Explosive & toxic chemicals in food"
    },
    {
      "source": "Vitamin D Deficiency",
      "target": "Functional Medicine Functioning"
    },
    {
      "source": "Vitamin D Deficiency",
      "target": "Nasties in beauty"
    },
    {
      "source": "Your energy source",
      "target": "Explosive & toxic chemicals in food"
    },
    {
      "source": "Your energy source",
      "target": "Functional Medicine Functioning"
    },
    {
      "source": "Your energy source",
      "target": "Fruit juice to fatty liver"
    },
    {
      "source": "Your energy source",
      "target": "Power up with potassium"
    },
    {
      "source": "Your energy source",
      "target": "Ketogenic diet and cancer"
    },
    {
      "source": "Your energy source",
      "target": "Take 11 minutes a day to postpone death"
    },
    {
      "source": "Your energy source",
      "target": "The healthy hungry age better"
    },
    {
      "source": "Your energy source",
      "target": "Be better than the conventional normal"
    },
    {
      "source": "Your energy source",
      "target": "Faster weight loss on slow thyroid"
    },
    {
      "source": "Your energy source",
      "target": "Supplements for your pretty face"
    }
  ]
}