import * as d3 from 'd3';
import {
  linkColor,
  linkWidth,
  nodesCirclesColor,
  nodesCirclesRadius,
  nodeDistance,
  labelDistance,
  mobileScreenHeight,
} from '../config.js';

export default class View {
  _parentElementId;
  _container;
  _containerEl;
  width;
  height;
  offsetWidth; // modify the centering of the svg
  _data;
  _svg;
  _simulation;
  _graphGroup;
  _links;
  _nodes;
  tooltip;
  isTooltipHovered;
  currentNode;
  currentNodeEl;
  tooltipRemovalTimeout;
  btnResizeEl;

  renderView(data) {
    // this.renderSpinner(); FIXME: icon is not read
    this._data = data;
    this.clearHTML(this._containerEl);
    this.createSVG();
    this.renderSimulation();
    this.createGraphGroup();
    this.renderLinks();
    this.renderNodes();
    this.addHandlerZoom();
    this.addHandlerDrag();
    this.createTooltipElement();
    // Bind events based on device type
    if (this.isMobile()) {
      this.bindMobileEvents();
    } else {
      this.bindDesktopEvents();
    }

    this.addHandlerHoverTooltip();
    this.addHandlerReloadSVG();
  }

  //HELPERS METHODS

  toggleHidden(element) {
    element.classList.toggle('hidden');
  }

  clearHTML(element) {
    element.innerHTML = '';
  }

  capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  calculateContainerWidth() {
    return (this.width = this._parentElementId.offsetWidth / this.offsetWidth);
  }
  calculateContainerHeight() {
    return (this.height = this._parentElementId.offsetHeight);
  }

  reRenderView(classInstance, getClonedData) {
    const newData = getClonedData();
    classInstance.clearHTML(classInstance._containerEl);
    classInstance.renderView(newData);
    this.currentNode = null; // allows to select new nodes
  }

  isMobile() {
    // Adjust the threshold as needed. Here we use 768px as an example.
    return window.innerWidth <= 768;
  }

  // renderSpinner() {
  //   this._containerEl.insertAdjacentHTML(
  //     'afterbegin',
  //     `<div class="spinner">
  //   <svg>
  //   <symbol id="icon-loader" viewBox="0 0 24 24">
  //   <path d="M11 2v4c0 0.552 0.448 1 1 1s1-0.448 1-1v-4c0-0.552-0.448-1-1-1s-1 0.448-1 1zM11 18v4c0 0.552 0.448 1 1 1s1-0.448 1-1v-4c0-0.552-0.448-1-1-1s-1 0.448-1 1zM4.223 5.637l2.83 2.83c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-2.83-2.83c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414zM15.533 16.947l2.83 2.83c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-2.83-2.83c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414zM2 13h4c0.552 0 1-0.448 1-1s-0.448-1-1-1h-4c-0.552 0-1 0.448-1 1s0.448 1 1 1zM18 13h4c0.552 0 1-0.448 1-1s-0.448-1-1-1h-4c-0.552 0-1 0.448-1 1s0.448 1 1 1zM5.637 19.777l2.83-2.83c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-2.83 2.83c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0zM16.947 8.467l2.83-2.83c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-2.83 2.83c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0z"></path>
  //   </symbol>
  //   </svg>
  // </div>`
  //   );
  // }

  //GRAPH

  createSVG() {
    return (this._svg = d3
      .select(this._container)
      .append('svg')
      .classed('graph-svg', true) // .graph-svg not defined !!!
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('viewport', [0, 0, this.width, this.height])) // Create SVG
      .style('border', 'solid 1px #afafaf')
      .style('overflow', 'hidden');
  }

  renderSimulation() {
    return (this._simulation = d3
      .forceSimulation(this._data.nodes)
      .force(
        'link',
        d3
          .forceLink(this._data.links)
          .id(d => d.id)
          .distance(nodeDistance)
      )
      .force('charge', d3.forceManyBody().distanceMax(100))
      .force('center', d3.forceCenter(this.width / 2, this.height / 2))
      .force(
        'collide',
        d3
          .forceCollide()
          .radius(d => {
            // Basic approach: add some buffer to the node radius
            // so that labels have space
            const labelPadding = 40; // extra space for the label
            return nodesCirclesRadius + labelPadding;
          })
          .iterations(2) // you can experiment with iteration count
      )
      .on('tick', () => this.ticked()));
  }

  ticked() {
    // Update link positions
    this._links
      .attr('x1', d => d.source.x)
      .attr('x2', d => d.target.x)
      .attr('y1', d => d.source.y)
      .attr('y2', d => d.target.y);

    // Update node positions - this only updates visualization, not the datum (data)
    this._nodes.attr('transform', d => `translate(${d.x}, ${d.y})`);
  }

  createGraphGroup() {
    return (this._graphGroup = this._svg.append('g'));
  }

  renderLinks() {
    this._links = this._graphGroup
      .selectAll('line')
      .data(this._data.links)
      .join('line')
      .attr('stroke', linkColor)
      .attr('stroke-width', linkWidth);
    return this._links;
  }

  renderNodes() {
    this.createNodeGroups();
    this.createNodesCircles();
    this.createNodesLabels();
  }

  createNodeGroups() {
    return (this._nodes = this._graphGroup
      .attr('class', 'nodes')
      .selectAll('g')
      .data(this._data.nodes)
      .enter() // enter the nodes from the array?
      .append('g')); // create 1 group per node (d)
  }

  createNodesCircles() {
    this._nodes
      .append('circle')
      .attr('r', nodesCirclesRadius)
      .attr('fill', nodesCirclesColor)
      .classed('select-element', true);
  }

  createNodesLabels() {
    this._nodes
      .append('text')
      .text(d => d.id)
      .attr('x', 0) // Center the text relative to the group
      .attr('y', labelDistance) // Position the text below the circle relative to the group
      .attr('text-anchor', 'middle') // Ensure the text is centered horizontally relative to itself
      .attr('alignment-baseline', 'middle') // Ensure the text is centered vertically relative to itself
      .classed('cursor', true);
  }

  //ZOOM METHODS

  addHandlerZoom() {
    // 1) Create a zoom behavior with your desired scale range
    const zoomBehavior = d3
      .zoom()
      .scaleExtent([0.1, 10])
      .on('zoom', event => {
        // 2) Apply the transform to the main <g> container
        this._graphGroup.attr('transform', event.transform);
      });

    // 3) Attach the zoom behavior to the SVG
    this._svg.call(zoomBehavior);
  }

  //DRAGGING

  addHandlerDrag() {
    this._nodes.call(
      d3
        .drag()
        .on('start', event => this.activateStartDragging(event))
        .on('drag', event => this.activateDragging(event))
        .on('end', event => this.activateEndDragging(event))
    );
  }

  activateStartDragging(event) {
    if (!event.active) {
      this._simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }
  }
  activateDragging(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;

    // Remove tooltip while dragging
    this.removeTooltip();
  }
  activateEndDragging(event) {
    if (!event.active) {
      this._simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }
  }

  // FIXME: timeout is not working / DELETE
  addHandlerNodesEnterEvents() {
    this._nodes.selectAll('circle').on('pointerenter', (event, d) => {
      // Store reference to the node group (parent element) for later removal of highlight
      this.currentNodeEl = event.currentTarget.parentNode; // HTML

      this.renderNodesHighlight(d, this.currentNodeEl);
      this.addHandlerRenderTooltip(event, d);
      if (this.currentNode === d) {
        clearTimeout(this.tooltipRemovalTimeout); // timeout for tooltip is cleared only if you are entering the same node
      } else {
        this.currentNode = d;
      }
    });
  }

  // HANDLERS FOR EVENTS

  bindDesktopEvents() {
    // On click: if no highlight is active, highlight the clicked node and its connected nodes,
    // and show the tooltip for the clicked node.
    this._nodes.selectAll('circle').on('click', (event, d) => {
      // Prevent new highlights if one is already active.
      if (this.currentNode) return;

      // Store the node group element for later reference.
      this.currentNodeEl = event.currentTarget.parentNode;
      // Highlight the clicked node and its connections.
      this.renderNodesHighlight(d, this.currentNodeEl);
      // Show tooltip for the clicked node.
      this.addHandlerRenderTooltip(event, d);
      // Set the current highlighted node.
      this.currentNode = d;
    });

    // Attach pointerenter to update the tooltip only for nodes that are part of the highlighted set.
    this._nodes.selectAll('circle').on('pointerenter', (event, d) => {
      const nodeGroup = event.currentTarget.parentNode;
      if (
        d === this.currentNode ||
        d3.select(nodeGroup).classed('highlighted-ConnectedNode')
      ) {
        this.addHandlerRenderTooltip(event, d);
      }
    });

    this._nodes.selectAll('circle').on('pointerleave', (event, d) => {
      // If leaving a highlighted connected node, revert tooltip to currentNode info.
      this.scheduleTooltipRemoval(this.isTooltipHovered);
    });

    // Reload svg when screens change
  }

  bindMobileEvents() {
    // On tap: if no highlight is active, highlight the tapped node and its connected nodes,
    // and show the tooltip for the tapped node.
    this._nodes.selectAll('circle').on('click', (event, d) => {
      const nodeGroup = event.currentTarget.parentNode;

      // If no node is highlighted yet, do the initial highlight.
      if (!this.currentNode) {
        this.currentNodeEl = nodeGroup;
        this.renderNodesHighlight(d, nodeGroup);
        this.addHandlerRenderTooltip(event, d);
        this.currentNode = d;
      } else {
        // If a node is already highlighted, only update the tooltip if the tapped node
        // is either the target or one of its connected nodes.
        if (
          d === this.currentNode ||
          d3.select(nodeGroup).classed('highlighted-ConnectedNode')
        ) {
          this.addHandlerRenderTooltip(event, d);
        }
      }
    });
  }

  //HIGHLIGHT NODES

  renderNodesHighlight(d, currentNode) {
    // Reduce the opacity of all nodes and links
    this.applyOverlayToAll();
    // Apply highlighted style to target node
    this.highlightTargetNode(currentNode);
    // Apply highlight style to the connected elements
    this.highlightConnections(d);
  }

  applyOverlayToAll() {
    this._nodes.classed('overlayed', true);
    this._links.classed('overlayed-links', true);
  }

  highlightTargetNode(nodeElement) {
    d3.select(nodeElement).select('circle').classed('highlighted-node', true);
    d3.select(nodeElement)
      .classed('overlayed', false)
      .select('text')
      .transition()
      .duration(200)
      .attr('y', labelDistance + 5);
  }

  unhighlightTargetNode(nodeElement) {
    d3.select(nodeElement)
      .classed('overlayed', false)
      .select('circle')
      .classed('highlighted-node', false);
    d3.select(nodeElement)
      .transition()
      .duration(200)
      .select('text')
      .attr('y', labelDistance);
  }

  // Takes in the d (datum) from the event handler. the d is target node in the form of object (as in state)
  highlightConnections(d) {
    const connectedLinks = this.getConnectedLinks(d);
    const connectedNodeIds = this.getConnectedNodes(connectedLinks);

    this._links
      .filter(link => connectedLinks.includes(link))
      .classed('highlighted-link', true)
      .classed('overlayed-links', false);

    this._nodes.each(function (nodeData) {
      if (nodeData.id === d.id) {
        return;
      } else if (connectedNodeIds.has(nodeData.id)) {
        d3.select(this).classed('highlighted-ConnectedNode', true);
      }
    });
  }

  getConnectedLinks(d) {
    return this._data.links.filter(
      link => link.source.id === d.id || link.target.id === d.id
    ); // Array of connected links [{source, target}]
  }

  getConnectedNodes(connectedLinks) {
    return new Set(
      connectedLinks.flatMap(link => [link.source.id, link.target.id])
    ); // Set of ids of connected nodes [id]
  }

  addHandlerNodesLeaveEvents() {
    this._nodes.selectAll('circle').on('mouseleave pointerdown', event => {
      if (event.type === 'mouseleave') {
        this.clearGraphState(this.currentNodeEl);
      }
      if (event.type === 'pointerdown') {
        this.addHandlerClearHighlightMobile();
      }
    });
  }

  addHandlerClearHighlightMobile() {
    this._containerEl.addEventListener('pointerdown', event => {
      if (
        !event.target.closest('.nodes') &&
        !event.target.closest('.tooltip')
      ) {
        this.clearGraphState(this.currentNodeEl);
      }
    });
  }

  clearGraphState(currentNodeEl) {
    this.removeNodesHighlight();
    this.unhighlightTargetNode(currentNodeEl);
    this.removeTooltip();
  }

  removeNodesHighlight() {
    this._nodes.classed('overlayed', false);
    this._nodes.classed('highlighted-node ', false);
    this._nodes.classed('highlighted-ConnectedNode ', false);
    this._links.classed('overlayed', false);
    this._links.classed('highlighted-link', false);
  }

  // TODO: add guard clause so you can avoid issues on mobile
  addHandlerReloadSVG() {
    window.addEventListener('resize', () => {
      // if (window.innerHeight <= mobileScreenHeight) return;
      this.calculateContainerHeight();
      this.calculateContainerWidth();
      this._svg.attr('width', this.width).attr('height', this.height);
    });
  }

  //TOOLTIP

  createTooltipElement() {
    this.tooltip = d3
      .select(this._container)
      .append('div')
      .attr('class', 'tooltip')
      .classed('hidden', true) // it has to be classed, not attr, or the last attr will overwrite the first
      .style('opacity', 0);

    return this.tooltip;
  }

  addHandlerRenderTooltip(event, d) {
    const targetNodeObject = this.identifyTargetNode(event);
    // Guard clause to avoid creating empty tooltip
    if (targetNodeObject.tldr.length === 0) return;

    if (this.isMobile()) {
      // For mobile: use a fixed overlay instead of JS-calculated positioning.
      this.tooltip
        .classed('overlay-mobile', true) // Apply your mobile overlay CSS class
        .classed('hidden', false) // Ensure hidden state is removed
        .style('position', 'fixed') // Ensure fixed positioning
        // Remove any inline left/top styles (we rely solely on CSS for placement)
        .style('left', null)
        .style('top', null)
        .html(this.generateTooltipMarkup(targetNodeObject));

      this.addHandlerCloseTooltipMobile();

      // Transition the tooltip into view using your CSS transitions.
      this.tooltip
        .transition()
        .duration(300)
        .style('transform', 'translateY(0)')
        .style('opacity', 1);
    } else {
      // Desktop: calculate position based on the node's transformed coordinates.
      const currentTransform = d3.zoomTransform(this._svg.node()); // retrieves the current zoom transform state of the nodes (transform object)
      const transformedX = currentTransform.applyX(d.x); // returns new coordinates for the node
      const transformedY = currentTransform.applyY(d.y); // returns new coordinates for the node

      this.tooltip.classed('hidden', false).node().scrollTop = 0;

      this.tooltip
        .html(this.generateTooltipMarkup(targetNodeObject))
        .classed('tooltip', true)
        .style('position', 'absolute');

      this.updateTooltipPosition(transformedX, transformedY, event);
      this.tooltip.transition().duration(200).style('opacity', 0.9);
    }
  }

  addHandlerHoverTooltip() {
    this.tooltip
      .on('pointerenter', () => {
        clearTimeout(this.tooltipRemovalTimeout);
        this.isTooltipHovered = true; // not sure this logic works ???
        this.tooltip.style('opacity', 0.9);
      })
      .on('mouseleave', () => {
        this.isTooltipHovered = false;
        this.scheduleTooltipRemoval(this.isTooltipHovered);
      });
  }

  scheduleTooltipRemoval(isTooltipHovered) {
    clearTimeout(this.tooltipRemovalTimeout);

    // setTimeout serves to delay the hiding of the tooltip so the user has time to hover over it (debounce strategy)
    // tooltipRemovalTimeout is an id returned by setTimeout, which can be used to clear the timer
    this.tooltipRemovalTimeout = setTimeout(() => {
      if (!isTooltipHovered) {
        this.removeTooltip();
      }
    }, 300);
  }

  removeTooltip() {
    this.tooltip.classed('hidden', true);
    this.tooltip.transition().duration(200).style('opacity', 0);
  }

  addHandlerCloseTooltipMobile() {
    this.tooltip.select('.close-overlay').on('click', () => {
      this.removeTooltip();
    });
  }

  renderTooltipMarkup(event) {
    return this.generateTooltipMarkup(this.identifyTargetNode(event));
  }

  // Maybe this can be avoided using the datum from d3 ???
  identifyTargetNode(event) {
    if (!event.target || !event.target.parentNode) return;
    //  DOM traversing - selecting the right element in the DOM
    const targetNodeParentEl = event.target.parentNode;

    // Identifying the text of the label and its guard clause
    const textLabel = targetNodeParentEl.querySelector('text');
    if (!textLabel) return;
    const nodeLabel = textLabel.textContent;

    //  Identifying target object between nodes
    const targetNodeObject = this._data.nodes.find(
      object => object.id === nodeLabel
    );
    if (!targetNodeObject) return;

    return targetNodeObject;
  }

  generateTooltipMarkup(targetNodeObject) {
    const tooltipContent = `
 <div class="tooltip-content">
 ${
   this.isMobile()
     ? '<button class="close-overlay" aria-label="Close">&times;</button>'
     : ''
 }
      <h5>Tl;Dr</h5>
      <ul>
      ${targetNodeObject.tldr
        .map(bullet => `<li class="tldr-text">${bullet}</li>`)
        .join('')}
      </ul>
      <div class="tooltip-expand">
       ${`<a class="nav-link" id="tooltip-link" href="${targetNodeObject.url}" target="_blank">Expand ></a>`}
       </div>
    </div>`;

    return tooltipContent;
  }

  // checkReleaseDate(targetNodeObject) {
  //   const releaseDate = new Date(targetNodeObject.released);
  //   const currentDate = new Date();

  //   if (releaseDate >= currentDate) {
  //     return 'Coming soon';
  //   } else {
  //     return `<a class="nav-link" href="${targetNodeObject.url}" target="_blank">Read more →</a>`;
  //   }
  // }

  updateTooltipPosition(transformedX, transformedY, event) {
    // TransformedX and transformedY are relative to the SVG, not the viewport. NodeRect is the position of the node relative to the SVG. All the calculations need to be done relative to the viewport. The positioning has to be relative to the SVG.
    const nodeRect = event.target.getBoundingClientRect();
    const containerRect = this._containerEl.getBoundingClientRect();
    const tooltipNode = this.tooltip.node();
    const tooltipRect = tooltipNode.getBoundingClientRect();

    // Determine horizontal positioning
    let left;
    if (nodeRect.x + tooltipRect.width + 7 < containerRect.right) {
      // Tooltip fits to the right of the node
      left = transformedX + 7;
    } else if (nodeRect.x - tooltipRect.width - 7 >= containerRect.left) {
      // Tooltip fits to the left of the node
      left = transformedX - tooltipRect.width - 7;
    } else {
      // Center the tooltip if it doesn't fit on either side
      left = containerRect.left + (containerRect.width - tooltipRect.width) / 2;
    }

    // Determine vertical positioning
    let top = transformedY;

    // If tooltip would overflow bottom
    if (nodeRect.bottom + tooltipRect.height > containerRect.bottom) {
      // Calculate how much it overflows
      const overflow =
        nodeRect.bottom + tooltipRect.height - containerRect.bottom;

      // If there's room to move it up entirely, do so
      if (nodeRect.bottom - overflow >= containerRect.top) {
        top -= overflow;
      } else {
        // Otherwise, center it vertically in the available space
        top =
          containerRect.top + (containerRect.height - tooltipRect.height) / 2;
      }
    }

    // Apply the calculated position
    this.tooltip.style('left', `${left}px`).style('top', `${top}px`);

    // If the tooltip is taller than the container, adjust its height
    if (tooltipRect.height > containerRect.height) {
      this.tooltip.style('max-height', `${containerRect.height - 10}px`);
    }
  }

  // RESIZE BUTTON

  addHandlerRecenterGraph(getClonedData) {
    // Guard clause
    if (!this.btnResizeEl) {
      console.error('Resize button not initialized');
      return;
    }

    this.btnResizeEl.addEventListener('click', e => {
      this.reRenderView(this, getClonedData);
    });
  }

  // RESIZE AFTER VIEWPORT CHANGE
  addHandlerResizeViewport(getClonedData) {
    window.addEventListener('resize', () => {
      if (!this.isMobile()) {
        // On desktop: re-render the view.
        this.reRenderView(this, getClonedData);
      } else {
        // On mobile: update the SVG dimensions only.
        this.calculateContainerHeight();
        this.calculateContainerWidth();
        this._svg.attr('width', this.width).attr('height', this.height);
        // Optionally, if you want to do nothing, you can simply return.
      }
    });
  }

  // UTILITY FUNCTIONS

  // addHandlerShowElement(btnClass, modal) {
  //   document.addEventListener('click', e => {
  //     const clickedBtn = e.target.closest(btnClass);
  //     const clickedModal = e.target.closest(modalClass);

  //     if (clickedBtn) {}
  //   });
  // }
}
