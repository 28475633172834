import View from './View.js';
import heroView from './heroView.js';

class ModalView extends View {
  _parentElementId = document.querySelector('.graph-modal-container');
  width;
  height;
  _container = '.graph-modal-svg';
  _containerEl = document.querySelector('.graph-modal-svg');
  offsetWidth = 1; // modify the centering of the svg
  _icon = document.querySelector('.graph-icon');
  _modal = document.querySelector('.graph-modal');
  _modalBtn = document.querySelector('.graph-modal-btn--resize');

  // add event handler
  addHandlerRenderModal(data) {
    this._icon.addEventListener('click', () => {
      console.log('START');
      this._modal.classList.remove('hidden');
      this.calculateContainerWidth();
      this.calculateContainerHeight();
      // ternary operator, either render the chart or refresh the chart
      this._parentElementId.querySelector('svg') !== null
        ? this.renderSimulation()
        : this.renderView(data);
    });
  }

  addHandlerCloseModal(getClonedData) {
    this._modal.addEventListener('click', e => {
      if (e.target !== this._modal) return; // guard clause to make sure that modal closes only on the transparent overlay
      this.toggleHidden(this._modal);
      // Resetting the view for the hero-container
      this.reRenderView(heroView, getClonedData);
    });
  }
}

export default new ModalView();
