import View from './View.js';
import { computePosition, flip, shift, offset } from '@floating-ui/dom';

class HeroView extends View {
  _parentElementId = document.querySelector('.graph-hero-container');
  _container = '.graph-container';
  _containerEl = document.querySelector('.graph-container');
  offsetWidth = 1; // modify the centering of the svg
  width = this._parentElementId.offsetWidth; // before it was divided by / this.offsetWidth to have 50% TODO: delete
  height = this._parentElementId.offsetHeight;
  btnResizeEl = document.querySelector('.btn-resize');
  btnShareEl = document.querySelector('.btn-share');
  shareContainerEl = document.querySelector('.share-container');
  btnCopyLinkEl = document.querySelector('.share-button__copy');
  notificationCopiedLinkEl = document.querySelector('.notification');

  constructor() {
    super();
    this._addEventHandlers();
  }

  _addEventHandlers() {
    this._addHandlerShareModal();
    this._addHandlerCopyLink();
  }

  _addHandlerShareModal() {
    this.btnShareEl.addEventListener('click', e => {
      e.stopPropagation();
      this.shareContainerEl.classList.toggle('hidden');

      document.addEventListener('click', e => {
        if (
          !this.shareContainerEl.contains(e.target) &&
          !this.btnShareEl.contains(e.target)
        ) {
          this.shareContainerEl.classList.add('hidden');
        }
      });
      this._positionTooltip();
    });
  }

  _positionTooltip() {
    computePosition(this.btnShareEl, this.shareContainerEl, {
      placement: 'bottom',
      middleware: [offset(7), flip(), shift()],
    }).then(({ x, y }) => {
      Object.assign(this.shareContainerEl.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  _addHandlerCopyLink() {
    this.btnCopyLinkEl.addEventListener('click', async () => {
      try {
        await navigator.clipboard.writeText(window.location.href);
        // Notification for copied link
        this.notificationCopiedLinkEl.classList.remove('hidden');
        setTimeout(() => {
          this.notificationCopiedLinkEl.classList.add('hidden');
        }, 2000);
        // Close the tooltip
        this.shareContainerEl.classList.toggle('hidden');
      } catch (err) {
        console.error('Failed to copy:', err);
      }
    });
  }
}

export default new HeroView();
